import { Combobox } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { QuickSearchResult } from '../../model/legoSet'
import Spinner from '../shared/spinner/spinner'
import { classNames } from '../../utils/helpers'

type Props = {
  filteredData: {
    isSearch: boolean
    resultFound: boolean
    data: QuickSearchResult[]
  }
  handleChange: (value: string) => void
  loading: boolean
  onSelect: (value: QuickSearchResult) => void
}
export default function SearchInput({
  filteredData,
  handleChange,
  onSelect,
  loading,
}: Props) {
  const [result, setResult] = useState<QuickSearchResult | null>(null)

  return (
    <Combobox
      as="div"
      className={
        'flex max-w-lg flex-1 justify-center lg:ml-6 lg:max-w-xs lg:justify-end'
      }
      value={result}
      onChange={(value: QuickSearchResult) => {
        setResult(value)
        onSelect(value)
        setResult(null)
      }}
    >
      <div className="relative w-full">
        <div className={'relative w-full max-w-lg lg:max-w-xs'}>
          <label htmlFor="search" className="sr-only">
            Search
          </label>
          <div className="relative text-gray-400 focus-within:text-gray-600">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
            </div>
            <Combobox.Input
              placeholder={'Zoeken ...'}
              className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:text-sm sm:leading-6"
              onChange={(event) => handleChange(event.target.value)}
              displayValue={(quickSearchResult: QuickSearchResult) =>
                quickSearchResult?.name
              }
            />
          </div>
        </div>

        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {!loading &&
            filteredData.data.map((result) => (
              <Combobox.Option
                key={result.setNum}
                value={result}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div>
                      <p className={'text-neutral-400'}>{result.setNum}</p>
                      <p className={'font-bold'}>{result.name}</p>
                    </div>
                  </>
                )}
              </Combobox.Option>
            ))}

          {loading && (
            <Combobox.Option
              className={
                'relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900'
              }
              disabled
              value={null}
            >
              <div className={'flex'}>
                <Spinner size="small" includeText={true} />
              </div>
            </Combobox.Option>
          )}
          {!loading && !filteredData.resultFound && filteredData.isSearch && (
            <Combobox.Option
              className={
                'relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900'
              }
              value={null}
              disabled
            >
              Geen resultaten gevonden
            </Combobox.Option>
          )}
        </Combobox.Options>
      </div>
    </Combobox>
  )
}
