import Image from 'next/image'
import { useUserProfile } from '../userprofile-provider'
import { classNames } from '../../utils/helpers'

export default function PPAvatar({className} : {className?: string}) {
  const { userProfile } = useUserProfile()

  function renderUserAvatar() {
    if (userProfile && userProfile.pictureUrl) {
      return (
        <Image
          className={classNames('h-8 w-8 rounded-full', className)}
          src={userProfile?.pictureUrl ? userProfile.pictureUrl : ''}
          alt=''
          width={8}
          height={8}
          unoptimized={true}
        />
      )
    }
    return (
      <svg
        className={classNames('h-8 w-8 rounded-full text-gray-300', className)}
        fill='currentColor'
        viewBox='0 0 24 24'
      >
        <path
          d='M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z' />
      </svg>
    )
  }

  return renderUserAvatar()
}
