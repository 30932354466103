import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import Image from 'next/image'
import { Fragment } from 'react'
import PPLink from '../shared/button/pp-link'
import { signIn, signOut, useSession } from 'next-auth/react'
import { useUserProfile } from '../userprofile-provider'
import Link from 'next/link'
import { useSearchParams } from 'next/navigation'
import Search from './search'
import { logoutUrl } from '../../services/AuthService'
import PPAvatar from '../shared/pp-avatar'
import { classNames } from '../../utils/helpers'

const navigation = [
  { id: 'themes', name: "Thema's", href: '/themes' },
  { id: 'sets', name: 'Sets', href: '/sets' },
  { id: 'new', name: 'Nieuw', href: '/new' },
  { id: 'coming-soon', name: 'Binnekort beschikbaar', href: '/coming-soon' },
]

type Props = {
  current: string
}

export default function HeaderMenu({ current }: Props) {
  const { data: session } = useSession()
  const { userProfile } = useUserProfile()
  const searchParams = useSearchParams()

  const userNavigation = [
    {
      name: 'Instellingen',
      href: '/settings',
    },
    {
      name: 'Dashboard',
      href: '/dashboard',
    },
  ]

  async function logout() {
    await signOut({ redirect: false })
    window.location.href = logoutUrl()
  }

  return (
    <Disclosure
      as="nav"
      className="mb-2 border-b border-gray-700 border-opacity-25 bg-gray-800 md:mb-0 lg:border-none"
    >
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
            <div className="relative flex h-16 items-center justify-between gap-4 lg:border-b lg:border-gray-700 lg:border-opacity-25">
              <div className="flex items-center px-2 lg:px-0">
                <div className="flex-shrink-0">
                  <PPLink href="/">
                    <div className={'flex items-center justify-center gap-2'}>
                      <Image
                        className="h-8 w-8"
                        src="/img/logo.png"
                        alt="Your Company"
                        width={8}
                        height={8}
                        unoptimized={true}
                      />
                      <span className={'hidden text-white md:block'}>
                        Price patrol
                      </span>
                    </div>
                  </PPLink>
                </div>
                <div className="hidden lg:ml-10 lg:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.id === current
                            ? 'bg-gray-900 text-white'
                            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'rounded-md px-3 py-2 text-sm font-medium'
                        )}
                        aria-current={item.id === current ? 'page' : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <Search></Search>
              <div className="flex lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              {!session && (
                <div className="hidden lg:ml-4 lg:block">
                  <button
                    onClick={() => signIn('auth0')}
                    className="text-sm font-semibold leading-6 text-gray-300"
                  >
                    Log in <span aria-hidden="true">&rarr;</span>
                  </button>
                </div>
              )}

              {session && (
                <div className="hidden lg:ml-4 lg:block">
                  <div className="flex items-center">
                    <Menu as="div" className="relative ml-3 flex-shrink-0">
                      <div>
                        <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                          <span className="sr-only">Open user menu</span>
                          <PPAvatar />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  href={item.href}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700'
                                  )}
                                >
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={() => logout()}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block w-full px-4 py-2 text-left text-sm text-gray-700'
                                )}
                              >
                                Log out
                              </button>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              )}
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.id === current
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                  aria-current={item.id === current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
            {session && (
              <div className="border-t border-gray-700 pt-4 pb-3">
                <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    <PPAvatar />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium leading-none text-white">
                      {userProfile?.firstName} {userProfile?.lastName}
                    </div>
                  </div>
                </div>
                <div className="mt-3 space-y-1 px-2">
                  {userNavigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
              </div>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
