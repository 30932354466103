import { LegoSet, LegoSetResponse, QuickSearchResult } from '../model/legoSet'
import { authorizedFetch } from '../model/auth-helpers'
import { Session } from 'next-auth'

export function getLegoSets(
  size: number,
  page: number,
  pieces: string[],
  prices: string[],
  age: string[],
  title: string,
  setNum: string,
  sort: string,
  theme?: number | null,
  addedSinceDays?: number,
  releaseDate?: string,
  onlySets?: boolean,
  lowest?: boolean,
  priceChangesInLastDays?: number,
  quickFilter?: string,
  onWishlist?: boolean,
  owned?: boolean,
  session?: Session | null
): Promise<LegoSetResponse> {
  const params = new URLSearchParams()
  params.append('size', String(size))
  params.append('page', String(page))
  params.append('pieces', pieces.filter((value) => value !== '').join(','))
  params.append('prices', prices.filter((value) => value !== '').join(','))
  params.append('age', age.join(','))
  params.append('onlySets', String(onlySets))
  params.append('name', title)
  params.append('setNum', setNum)
  if (quickFilter) {
    params.append('sort', quickFilter)
  }
  params.append('sort', sort)
  params.append('sort', `setNum`)
  addedSinceDays && params.append('addedSinceDays', `${addedSinceDays}`)
  releaseDate && params.append('releaseDate', `${releaseDate}`)
  theme && params.append('theme', String(theme))
  lowest && params.append('lowestEver', String(lowest))
  priceChangesInLastDays &&
    params.append('priceChangesInLastDays', String(priceChangesInLastDays))

  if (session) {
    onWishlist && params.append('onWishlist', String(onWishlist));
    owned && params.append('owned', String(owned));

    return authorizedFetch(`${process.env.NEXT_PUBLIC_API_URL}/api/sets?${params.toString()}`, {}, session ?? null)
      .then((res) => res?.json())
      .then((data: LegoSetResponse) => {
        return data
      })

  } else {
    return fetch(`${process.env.NEXT_PUBLIC_API_URL}/api/sets?${params.toString()}`)
      .then((res) => res.json())
      .then((data: LegoSetResponse) => {
        return data
      })

  }

}

export function quickSearch(search: string): Promise<QuickSearchResult[]> {
  return fetch(`${process.env.NEXT_PUBLIC_API_URL}/api/search?term=${search}`)
    .then((res) => res.json())
    .then((data: QuickSearchResult[]) => {
      return data
    })
}

export async function loadLegoSet(setId: string): Promise<LegoSet | undefined> {
  const data = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/api/sets/${setId}`
  )
  return data.json()
}

export async function loadSimilarLegoSets(setId: string) {
  const data = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/api/sets/${setId}/similar?size=4`
  )
  return data.json()
}
