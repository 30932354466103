import Link from 'next/link'

export default function Footer() {
  return (
    <footer className={' bg-gray-800'}>
      <div
        className={
          'mx-auto max-w-7xl p-4 shadow sm:px-6 md:flex md:items-center md:justify-between md:p-6 lg:px-8'
        }
      >
        <span
          className={'text-sm text-gray-500 dark:text-gray-400 sm:text-center'}
        >
          © {new Date().getFullYear()}{' '}
          <a href="https://vdeit.com/" className={'hover:underline'}>
            Van Den Elshout IT
          </a>{' '}
          &{' '}
          <a href="https://picavet-it.com/" className={'hover:underline'}>
            Picavet IT
          </a>
          . Alle rechten voorbehouden.
        </span>
        <ul
          className={
            'mt-3 flex flex-wrap items-center text-sm text-gray-500 dark:text-gray-400 sm:mt-0'
          }
        >
          <li>
            <Link href="/about" className={'mr-4 hover:underline md:mr-6'}>
              Over ons
            </Link>
          </li>
          <li>
            <Link href="/contact" className={'mr-4 hover:underline  md:mr-6'}>
              Contacteer
            </Link>
          </li>
          <li>
            <Link href="privacy-policy" className={'hover:underline'}>
              Privacybeleid
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  )
}
