import { useEffect, useState } from 'react'

import SearchInput from './search-input'
import { quickSearch } from '../../services/LegoSetService'
import { useRouter } from 'next/navigation'
import { useDebounce } from 'usehooks-ts'


export default function Search() {
  const router = useRouter()

  const [search, setSearch] = useState('')
  const debouncedValue = useDebounce<string>(search, 500)

  const [loading, setLoading] = useState(false)
  const [filteredData, setFilteredData] = useState({
    data: [] as { setNum: string; name: string }[],
    isSearch: false,
    resultFound: false,
  })

  function filterData() {
    setLoading(true)
    quickSearch(debouncedValue)
      .then(value => {
        setFilteredData({
          data: value,
          isSearch: debouncedValue.trim().length > 0,
          resultFound: value?.length > 0,
        })
        setLoading(false)
      })
  }

  useEffect(filterData, [debouncedValue])

  return (
    <SearchInput
      handleChange={value => {
        setLoading(true)
        setSearch(value)
      }
      }
      filteredData={filteredData}
      loading={loading}
      onSelect={value => {
        router.push(`/sets/${value.setNum}`)
      }} />
  )
}
