import HeaderMenu from './header-menu'
import Footer from './footer'

type Props = {
  current: string
  children: JSX.Element
  padding?: boolean
  bgVariant?: string
}

export default function StackedLayout({
  children,
  current,
  padding = true,
  bgVariant = 'white',
}: Props) {
  return (
    <div className="flex min-h-screen flex-col">
      <div className="bg-gray-800 pb-32">
        <HeaderMenu current={current} />
      </div>

      <main className="-mt-32 flex-grow">
        <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
          <div
            className={`overflow-hidden rounded-lg 
            ${padding ? 'px-5 py-6 sm:px-6' : ''} 
            ${bgVariant === 'white' ? 'bg-white shadow ' : ''} 
            ${bgVariant === 'transparent' ? 'bg-transparent' : ''} 
            `}
          >
            {children}
          </div>
        </div>
      </main>

      <Footer />
    </div>
  )
}
