import Link from 'next/link'
import { ReactNode } from 'react'

type Props = {
  href: string
  children: ReactNode
}

export default function PPLink({ href, children }: Props) {
  return (
    <Link href={href} className={'text-blue-400'}>
      {children}
    </Link>
  )
}
